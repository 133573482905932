import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { createCustomElement } from "@angular/elements";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@services/auth";

import { ShipmentService } from "../../services/shipment.service";
import { IntervalService } from "../../services/interval.service";
import { MapCardComponent } from "../map-card/map-card.component";
import { RowDetailsComponent } from "../row-details/row-details.component";
import { PingPopupComponent } from "../ping-popup/ping-popup.component";

@Component({
  selector: "app-main-page",
  templateUrl: "./main-page.component.html",
  styleUrls: ["./main-page.component.scss"],
})
export class MainPageComponent implements OnInit, OnDestroy {
  title = "unos-organ-transplant-dashboard-web";
  shipments: any;
  currentView: "map" | "list" = "map";

  intervalSubscription: Subscription | null = null;

  constructor(
    private intervalService: IntervalService,
    private shipmentService: ShipmentService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    injector: Injector
  ) {
    const MapCardElement = createCustomElement(MapCardComponent, {
      injector,
    });
    if (customElements.get("mapcard-element") == null) {
      customElements.define("mapcard-element", MapCardElement);
    }
    const RowDetailsElement = createCustomElement(RowDetailsComponent, {
      injector,
    });
    if (customElements.get("rowdetails-element") == null) {
      customElements.define("rowdetails-element", RowDetailsElement);
    }
    const PingPopupElement = createCustomElement(PingPopupComponent, {
      injector,
    });
    if (customElements.get("pingpopup-element") == null) {
      customElements.define("pingpopup-element", PingPopupElement);
    }
  }

  ngOnInit() {
    if (this.authService.isActive()) {
      this.setupInterval();
    } else {
      let code = this.route.snapshot.queryParamMap.get("code") ?? undefined;
      this.authService
        .login(code)
        ?.pipe()
        .subscribe((_) => {
          if (this.authService.isActive()) {
            this.setupInterval();
            this.router.navigate(["/"]).then((_) => window.location.reload());
          } else {
            console.log("Can't log in");
          }
        });
    }
  }

  setupInterval() {
    this.intervalSubscription = this.intervalService
      .getInterval()
      .subscribe(
        (t) => typeof t !== "undefined" && this.shipmentService.refresh()
      );
  }

  ngOnDestroy() {
    this.intervalSubscription && this.intervalSubscription.unsubscribe();
    this.intervalSubscription = null;
  }

  switchToView(event: "map" | "list") {
    this.currentView = event;
  }
}
