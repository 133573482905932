<div class="padding">
  <table
    datatable
    [dtTrigger]="dtTrigger"
    [dtOptions]="dtOptions"
    class="table-view"
  >
    <thead>
      <tr>
        <th></th>
        <th>Donor ID</th>
        <th>Organ</th>
        <th>Destination</th>
        <th>Status</th>
        <th>Battery</th>
        <th>Device</th>
        <th>&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr class="table-row" *ngFor="let shipment of shipments">
        <td>{{ shipment.id }}</td>
        <td class="donor">{{ shipment.donorId }}</td>
        <td class="organ">{{ shipment.organ.name }}</td>
        <td class="destination">
          {{ shipment.destinationParsed?.opo ?? "To be defined" }}
        </td>
        <td [ngClass]="'status ' + getStatusName(shipment.status.id)">
          {{ shipment.status.name }}
        </td>
        <td class="battery">{{ shipment.battery ?? "??" }}%</td>
        <td class="device">{{ shipment.deviceName }}</td>
        <td class="details">
          View details&nbsp;<img src="/assets/down-blue.svg" />
        </td>
      </tr>
    </tbody>
  </table>
</div>
