import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

import { GegoShipment, GegoDevice } from "./models";

export interface GegoService {
  getAllShipments(): Observable<GegoShipment[]>;

  getSingleShipment(shipmentId: number | string): Observable<GegoShipment>;

  getAllDevicesV2(): Observable<GegoDevice[]>;

  getSingleDeviceV2(deviceId: number): Observable<GegoDevice>;
}

export let GegoService = new InjectionToken<GegoService>("GegoService");
