import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { delay, EMPTY, Observable, of, retry } from "rxjs";
import { AuthService } from "@services/auth";
import { environment } from "src/environments/environment";

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retry({
        count: environment.retryConfig.attempts,
        delay: (error: HttpErrorResponse) => this.handleApiError(error),
      })
    );
  }

  private handleApiError(error: HttpErrorResponse): Observable<boolean> {
    let retrySettings = environment.retryConfig;
    if (
      this.authService.isActive() &&
      error.status in retrySettings.retryableHttpResponseCodes
    )
      return of(true).pipe(delay(retrySettings.delay));
    throw error;
  }
}
