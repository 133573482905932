import { Provider } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { AuthInterceptor } from "./auth.interceptor";
import { CorsInterceptor } from "./cors.interceptor";
import { HttpCancelInterceptor } from "./http-cancel.interceptor";
import { RetryInterceptor } from "./retry.interceptor";

export const httpInterceptorProviders: Provider[] = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CorsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpCancelInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
];
