import {
  EventEmitter,
  Component,
  OnDestroy,
  Output,
  OnInit,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { DateTime } from "luxon";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import {
  FilterSelectModel,
  FilterService,
  StatusFilter,
} from "src/app/services/filter.service";

import { IntervalService } from "src/app/services/interval.service";
import {
  ShipmentFilters,
  ShipmentService,
} from "src/app/services/shipment.service";
import { UnosService } from "@services/unos";
import { formatTime } from "src/utils";
import { FilterSelectComponent } from "../filter-select/filter-select.component";

@Component({
  selector: "app-appbar",
  templateUrl: "./appbar.component.html",
  styleUrls: ["./appbar.component.scss"],
})
export class AppbarComponent implements OnInit, OnDestroy {
  intervalSub: Subscription | null = null;
  optionSub: Subscription | null = null;
  unosUpdateSub: Subscription | null = null;
  filterSub: Subscription | null = null;
  lastUpdated: string;
  @Output() mapOrListEvent = new EventEmitter<"map" | "list">();
  @Output() drawerEvent = new EventEmitter<"hidden" | "shown">();

  @ViewChildren(FilterSelectComponent)
  filterSelects!: QueryList<FilterSelectComponent>;

  mapOrList: "map" | "list" = "map";
  drawerState: "hidden" | "shown" = "hidden";
  activeStatus: StatusFilter = "all";
  filterOptions: { [Property in ShipmentFilters]: FilterSelectModel[] } | null =
    null;
  isEmpty: boolean = false;
  isLoaded: boolean = false;
  private opoIsSet: boolean = false;

  constructor(
    private intervalService: IntervalService,
    private shipmentService: ShipmentService,
    private unosService: UnosService,
    private filterService: FilterService
  ) {
    this.lastUpdated = this.getTime();
  }

  ngOnInit(): void {
    this.intervalSub = this.intervalService
      .getInterval()
      .subscribe((_) => this.handleInterval());
    this.unosUpdateSub = this.unosService.unosOpo.subscribe((access) => {
      this.clearAll(false);
      this.opoIsSet = typeof access !== "undefined" && access![0] != null;
    });
    this.filterSub = this.shipmentService
      .getFilteredStore()
      .subscribe((shipments) => {
        this.isLoaded = shipments.loaded && this.opoIsSet;
        this.isEmpty = shipments.loaded && shipments.store.length == 0;
      });
  }

  handleInterval() {
    this.lastUpdated = this.getTime();
  }

  handleOptions(options: {
    [Property in ShipmentFilters]: FilterSelectModel[];
  }) {
    this.filterOptions = options;
  }

  handleFilter(status: StatusFilter) {
    this.activeStatus = status;
  }

  getTime() {
    return formatTime(DateTime.now());
  }

  viewSwitch(which: "map" | "list") {
    this.mapOrList = which;
    this.mapOrListEvent.emit(which);
  }

  ngOnDestroy(): void {
    this.intervalSub && this.intervalSub.unsubscribe();
    this.intervalSub = null;
    this.unosUpdateSub && this.unosUpdateSub.unsubscribe();
    this.unosUpdateSub = null;
    this.filterSub && this.filterSub.unsubscribe();
    this.filterSub = null;
  }

  filterOn(status: StatusFilter) {
    this.activeStatus = status;
    this.clearAll();
    this.filterService.setStatusFilter(status);
  }

  expandDrawer() {
    this.drawerState = this.drawerState == "hidden" ? "shown" : "hidden";
  }

  getFilters(filter: ShipmentFilters) {
    return this.shipmentService
      .getOptions()
      .pipe(map((options) => options[filter]));
  }

  clearAll(sendEvent = true) {
    this.filterService.clearAllFilters(sendEvent);
    this.filterSelects?.forEach((x) => x.onClearAll());
  }
}
