import { InjectionToken } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import {
  OrganLookup,
  UnosOpo,
  UnosOpoAccess,
  UnosShipment,
  UnosStatusLookup,
  UnosUserAccess,
} from "./models";

export interface UnosService {
  unosOpo: BehaviorSubject<[UnosOpo | null, UnosUserAccess] | null>;

  getCenterHeaders(): {
    headers: {
      [header: string]: string;
    };
  };

  getAllDeviceShipments(): Observable<UnosShipment[]>;

  getSingleDeviceShipment(id: string): Observable<UnosShipment>;

  getOrganLookup(): Observable<OrganLookup[]>;

  getStatusLookup(): Observable<UnosStatusLookup[]>;

  getUserAccess(): Observable<UnosUserAccess>;

  setOpo(opo: UnosOpo | null): void;

  verifyOpo(accessibleOpos: UnosOpoAccess[], opo: UnosOpo | null): boolean;
}

export let UnosService = new InjectionToken<UnosService>("UnosService");
