import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { WithProperties } from "@angular/elements";
import { DataTableDirective } from "angular-datatables";
import { Subject, Subscription } from "rxjs";
import {
  ShipmentService,
  ShipmentStore,
} from "src/app/services/shipment.service";
import { statusName } from "src/utils";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements AfterViewInit, OnInit, OnDestroy {
  storeSub: Subscription | null = null;
  dtTrigger: Subject<DataTables.Settings> = new Subject();
  dtOptions: DataTables.Settings = {
    language: {
      emptyTable: "",
      zeroRecords: "",
    },
    pagingType: "full_numbers",
    columns: [
      // id
      {
        visible: false,
      },
      // donor id
      {
        orderable: true,
      },
      // organ
      {
        orderable: true,
      },
      // destination
      {
        orderable: true,
      },
      // status
      {
        orderable: true,
      },
      // battery
      {
        orderable: false,
      },
      // device
      {
        orderable: true,
      },
      // link
      {
        orderable: false,
      },
    ],
    rowCallback: (row: Node, data: any[] | Object, _index: number) => {
      const self = this;
      $("td", row).off("click");
      $("td", row).on("click", () => {
        self.clickHandler(row as HTMLElement, data);
      });

      if (data instanceof Array) {
        const id = data[0] as number;
        if (this.currentShipment == id) {
          this.setCurrent(row as HTMLElement, id);
        }
      }
      return row;
    },
    drawCallback: this.getDrawCallback(),
    pageLength: 15,
    dom: "ftip",
  };
  shipments: ShipmentStore[] = [];
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  currentDetails: DataTables.RowChildMethods | null = null;
  currentShipment: number | null = null;
  isEmpty: boolean = false;
  loaded: boolean = false;

  constructor(private shipmentService: ShipmentService) {}

  ngOnInit(): void {
    this.storeSub = this.shipmentService
      .getFilteredStore()
      .subscribe((shipments) => {
        this.shipments = shipments.store;
        this.loaded = shipments.loaded;
        this.isEmpty = this.loaded && shipments.store.length == 0;
        this.rerender();
      });
  }

  ngAfterViewInit() {
    this.dtTrigger.next(this.dtOptions);
  }

  rerender() {
    this.dtElement?.dtInstance.then((dtInstance) => {
      dtInstance.destroy();
      this.dtTrigger.next(this.dtOptions);
    });
  }

  ngOnDestroy(): void {
    this.storeSub && this.storeSub.unsubscribe();
    this.storeSub = null;
    this.dtTrigger.unsubscribe();
  }

  getStatusName(id: number): string {
    return statusName(id);
  }

  async clickHandler(row: HTMLElement, data: any[] | Object) {
    if (this.currentDetails) {
      this.currentDetails.hide();
    }
    this.currentDetails = null;
    if (!(data instanceof Array)) {
      return;
    }

    const lastSelected = document.querySelector(".shipment-selected");
    if (lastSelected) {
      const classes = lastSelected.className;
      const i = classes.indexOf("shipment-selected");
      if (i) {
        lastSelected.className =
          classes.substring(0, i) +
          classes.substring(i + "shipment-selected".length);
      }
    }

    const id = data[0] as number;
    if (this.currentShipment == id) {
      this.currentShipment = null;
      return;
    }
    this.currentShipment = id;

    this.setCurrent(row, id);
  }

  async setCurrent(row: HTMLElement, id: number) {
    const shipment = this.shipments.find((x) => x.id == id);

    if (!shipment) {
      this.currentDetails = null;
      return;
    }
    const detail = document.createElement("rowdetails-element") as HTMLElement &
      WithProperties<{
        shipment: ShipmentStore;
      }>;
    detail.shipment = shipment;
    row.className += " shipment-selected";
    const instance = await this.dtElement?.dtInstance;
    this.currentDetails = instance.row(row).child(detail, "child-row");
    this.currentDetails.show();
  }

  getDrawCallback() {
    const self = this;
    console.log(self);
    return function (this: any, _settings: {}) {
      const api = this.api();
      self.isEmpty =
        self.loaded && api.rows({ search: "applied" }).count() == 0;
    };
  }
}
