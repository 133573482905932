import { HttpClient } from "@angular/common/http";
import { Injectable, Provider } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, share } from "rxjs/operators";

import { UnosService } from "@services/unos";
import { environment } from "src/environments/environment";
import { GegoService } from "./gego.interface";
import { GegoDevice, GegoShipment } from "./models";

@Injectable()
export class GegoHttpService implements GegoService {
  constructor(private http: HttpClient, private unosService: UnosService) {}

  private getUrl<T>(url: string): Observable<T> {
    return this.http
      .get<T>(
        `${environment.gegoApiBase}${url}`,
        this.unosService.getCenterHeaders()
      )
      .pipe(
        catchError((_) => of({} as T)),
        share()
      );
  }

  getAllShipments(): Observable<GegoShipment[]> {
    return this.getUrl<GegoShipment[]>("/api/Shipments");
  }

  getSingleShipment(shipmentId: number | string): Observable<GegoShipment> {
    return this.getUrl<GegoShipment>(`/api/Shipments/${shipmentId}`);
  }

  getAllDevicesV2(): Observable<GegoDevice[]> {
    return this.getUrl<GegoDevice[]>("/api/v2/devices/");
  }

  getSingleDeviceV2(deviceId: number): Observable<GegoDevice> {
    return this.getUrl<GegoDevice>(`/api/v2/devices/${deviceId}`);
  }
}

export const gegoProviders: Provider[] = [
  { provide: GegoService, useClass: GegoHttpService },
];
