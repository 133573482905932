import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import {
  FilterSelectModel,
  FilterService,
} from "src/app/services/filter.service";
import {
  ShipmentFilters,
  ShipmentService,
} from "src/app/services/shipment.service";
import { NgSelectComponent } from "@ng-select/ng-select";

@Component({
  selector: "app-filter-select",
  templateUrl: "./filter-select.component.html",
  styleUrls: ["./filter-select.component.scss"],
})
export class FilterSelectComponent implements OnInit, OnDestroy {
  @Input() placeholder!: string;
  @Input() multiple: boolean = true;
  @Input() searchable: boolean = true;
  @Input() property!: ShipmentFilters;
  @ViewChild(NgSelectComponent) ngSelectComponent!: NgSelectComponent;

  selectedItem: number | null = null;
  itemsSub: Subscription | null = null;
  options: (FilterSelectModel & { checked: boolean })[] = [];
  sendEvents: boolean = true;

  constructor(
    private shipmentService: ShipmentService,
    private filterService: FilterService
  ) {}

  onChange(selected?: typeof this.options | typeof this.options[0] | Event) {
    if (selected instanceof Event) {
      return;
    }
    if (selected instanceof Array) {
      this.filterService.clearFilter(this.property, this.sendEvents);

      for (let select of selected) {
        this.filterService.addFilter(this.property, select.id, this.sendEvents);
      }

      const ids = selected.map((sel) => sel.id);
      for (let item of this.options) {
        item.checked = ids.includes(item.id);
      }
    } else if (typeof selected !== "undefined") {
      this.filterService.clearFilter(this.property, this.sendEvents);
      this.filterService.addFilter(this.property, selected.id, this.sendEvents);
    } else {
      this.filterService.clearFilter(this.property, this.sendEvents);
    }
  }

  onClearAll() {
    this.sendEvents = false;
    this.ngSelectComponent.handleClearClick();
    this.ngSelectComponent.blur();
    this.sendEvents = true;
  }

  ngOnInit(): void {
    this.itemsSub = this.shipmentService
      .getOptions()
      .pipe(map((x) => x[this.property]))
      .subscribe((items: FilterSelectModel[]) => {
        this.options = items.map((x) => ({ ...x, checked: false }));
      });
  }

  ngOnDestroy() {
    this.itemsSub && this.itemsSub.unsubscribe();
  }
}
