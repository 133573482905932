import { Component, OnInit } from "@angular/core";
import { AuthService } from "@services/auth";
import { GegoService } from "@services/gego";
import { UnosService } from "@services/unos";
import { IntervalService } from "src/app/services/interval.service";
import { ShipmentService } from "src/app/services/shipment.service";
import { UnosOpo, UnosUserAccess } from "@services/unos/models";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  name: String | null = null;
  initials: String | null = null;
  currentOpo: UnosOpo | null = null;
  opoSelections: UnosOpo[] = [];
  expanded: boolean = false;

  constructor(
    private unosService: UnosService,
    private authService: AuthService,
    private intervalService: IntervalService,
    private shipmentService: ShipmentService,
    private gegoService: GegoService
  ) {}

  ngOnInit(): void {
    this.unosService.unosOpo.subscribe((access) => {
      if (access == null) {
        return;
      }
      const [currentOpo, credentials] = access;
      this.handleUpdate(currentOpo, credentials);
    });
  }

  handleUpdate(currentOpo: UnosOpo | null, credentials: UnosUserAccess) {
    const { UserFirstName: firstName, UserLastName: lastName } = credentials;

    this.name = `${firstName} ${lastName}`;
    this.initials = `${firstName[0]}${lastName[0]}`.toUpperCase();
    this.currentOpo = currentOpo ?? null;
    this.opoSelections = credentials.OposUserHasAccessTo.map((opo) => ({
      centerCode: opo.CenterCode,
      centerType: opo.CenterType,
    }));
  }

  getOpoName() {
    if (this.currentOpo) {
      return `${this.currentOpo.centerCode}-${this.currentOpo.centerType}`;
    }
    return "";
  }

  expand(state: boolean) {
    this.expanded = state;
  }

  selectOpo(opo: typeof this.currentOpo) {
    this.unosService.setOpo(opo);
  }

  logout() {
    this.shipmentService.clearStore();
    this.intervalService.stopInterval();
    this.authService.logout();
  }
}
