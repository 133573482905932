import { Component, Input, OnInit } from "@angular/core";
import { DateTime } from "luxon";
import { ShipmentStore } from "src/app/services/shipment.service";
import { formatTime, statusName } from "src/utils";

@Component({
  selector: "app-row-details",
  templateUrl: "./row-details.component.html",
  styleUrls: ["./row-details.component.scss"],
})
export class RowDetailsComponent {
  @Input() shipment!: ShipmentStore;

  constructor() {}

  getLastUpdated() {
    return this.shipment.lastUpdated
      ? formatTime(DateTime.fromJSDate(this.shipment.lastUpdated))
      : "";
  }

  isDelivered() {
    return statusName(this.shipment.status.id) === "delivered";
  }

  isActive() {
    return statusName(this.shipment.status.id) === "active";
  }

  getDelivered() {
    return this.shipment.deliveredDate
      ? formatTime(DateTime.fromJSDate(this.shipment.deliveredDate))
      : "";
  }
}
