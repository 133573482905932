import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class CorsInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.shouldHandle(request)) {
      return next.handle(request.clone({ withCredentials: true }));
    }
    return next.handle(request);
  }

  shouldHandle(request: HttpRequest<unknown>): boolean {
    return (
      (environment.unosApiBase != "" &&
        request.url.includes(environment.unosApiBase)) ||
      (environment.gegoApiBase != "" &&
        request.url.includes(environment.gegoApiBase)) ||
      (environment.unosLoginApiBase != "" &&
        request.url.includes(environment.unosLoginApiBase))
    );
  }
}
