export const environment = {
  production: false,
  mapboxKey:
    "pk.eyJ1IjoidW5vc21hcGJveCIsImEiOiJjbDNieTBueWEwMnN5M29vNmhlOHd6bnM5In0.IDxKF4BvUQj0bL1Wk7Fzlg",
  unosApiBase:
    "https://app-shipment-tracking-dev.cloud.unos.org/api-proxy/shipment-tracking/v1",
  unosLoginApiBase:
    "https://app-shipment-tracking-dev.cloud.unos.org/api/access",
  gegoApiBase: "https://app-shipment-tracking-dev.cloud.unos.org/gego-proxy",
  extraHeaders: {},
  retryConfig: {
    retryableHttpResponseCodes: [408, 429, 500, 501],
    delay: 100,
    attempts: 1,
  },
  AppInsightsConnectionString:
    "InstrumentationKey=57818f2c-fc80-418a-865b-2fe00e08bb2c;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/",
  tickRate: 60 * 1000, // 1 minute
  lookbackDays: 365 * 40,
};
