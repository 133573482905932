import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from "@angular/core";
import { NgElement, WithProperties } from "@angular/elements";
import { DateTime } from "luxon";
import { ShipmentStore } from "src/app/services/shipment.service";
import * as utils from "src/utils";

export type CardElem = NgElement &
  WithProperties<{
    shipment: ShipmentStore;
    state: CardState;
  }>;

export type CardState = "hovered" | "brief" | "detailed" | "pin";

@Component({
  selector: "app-map-card",
  templateUrl: "./map-card.component.html",
  styleUrls: ["./map-card.component.scss"],
})
export class MapCardComponent {
  @Input() shipment!: ShipmentStore;
  @Input() state: CardState = "pin";
  @Output() stateOutput: EventEmitter<CardState> =
    new EventEmitter<CardState>();

  fromDetails: boolean = false;

  constructor() {}

  @HostListener("click")
  onClick() {
    // a click from the handleViewDetailsClose will also cause a general click event
    // we want to prevent that.
    if (this.fromDetails) {
      this.fromDetails = false;
      return;
    }

    if (this.state == "pin") {
      this.stateOutput.emit("brief");
    }
  }

  @HostListener("mouseenter")
  onHover() {
    if (this.state == "pin") {
      this.stateOutput.emit("hovered");
    }
  }

  @HostListener("mouseleave")
  onMouseLeave() {
    if (this.state == "brief") {
      this.stateOutput.emit("pin");
    }
  }

  handleViewDetailsOpen() {
    this.stateOutput.emit("detailed");
  }

  handleViewDetailsClose() {
    this.fromDetails = true;
    this.stateOutput.emit("pin");
  }

  getLastUpdated(): string {
    if (this.shipment.lastUpdated !== null) {
      return utils.formatTime(DateTime.fromJSDate(this.shipment.lastUpdated));
    }
    return "UNKOWN UPDATE TIME";
  }

  getDelivered(): string {
    if (this.shipment.deliveredDate) {
      return utils.formatTime(DateTime.fromJSDate(this.shipment.deliveredDate));
    }
    return "UNKNOWN DELIVERY TIME";
  }

  statusName(): string {
    return utils.statusName(this.shipment.status.id);
  }

  statusClassArrow(): string {
    return `arrow--${this.statusName()}`;
  }

  statusClassFill(): string {
    return `fill--${this.statusName()}`;
  }

  organImage(): string {
    switch (this.shipment.organ.id) {
      case utils.Organ.HEART:
        return "/assets/heart.svg";
      case utils.Organ.LEFT_LUNG:
        return "/assets/left-lung.svg";
      case utils.Organ.RIGHT_LUNG:
        return "/assets/right-lung.svg";
      case utils.Organ.LUNG_EN_BLOC:
        return "/assets/lung-en-bloc.svg";
      case utils.Organ.LIVER:
        return "/assets/liver.svg";
      case utils.Organ.LIVER_SPLIT_LEFT:
        return `/assets/liver-split-left.svg`;
      case utils.Organ.LIVER_SPLIT_RIGHT:
        return `/assets/liver-split-right.svg`;
      case utils.Organ.PANCREAS:
        return "/assets/pancreas.svg";
      case utils.Organ.KIDNEY_LEFT:
        return "/assets/kidney-left.svg";
      case utils.Organ.KIDNEY_RIGHT:
        return "/assets/kidney-right.svg";
      case utils.Organ.KIDENY_EN_BLOCK:
        return "/assets/kidney-en-bloc.svg";
      case utils.Organ.INTENSTINE:
        return "/assets/intenstine.svg";
      default:
        throw new Error("Unknown organ");
    }
  }

  isDelivered() {
    return this.statusName() == "delivered";
  }
}
