import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError, EMPTY, NEVER, Observable } from "rxjs";
import { AuthService } from "@services/auth";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!this.shouldHandle(request)) {
      return next.handle(request);
    }
    if (!this.authService.isActive()) {
      return NEVER;
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.logout();
          return EMPTY;
        }
        throw error;
      })
    );
  }

  shouldHandle(request: HttpRequest<unknown>): boolean {
    return (
      (environment.unosApiBase != "" &&
        request.url.includes(environment.unosApiBase)) ||
      (environment.gegoApiBase != "" &&
        request.url.includes(environment.gegoApiBase))
    );
  }
}
