<div class="pin">
  <button
    (click)="expand(true)"
    (mouseleave)="expand(false)"
    class="profile {{ expanded && 'expanded' }}"
  >
    <div class="profile-container">
      <div class="initials">
        {{ initials }}
      </div>
      <div class="opo">
        {{ getOpoName() }}
      </div>
      <div class="down-arrow">
        <img src="/assets/icon-down-arrow.svg" />
      </div>
    </div>
    <div *ngIf="expanded" class="expansion">
      <div class="name">
        {{ name }}
      </div>
      <div>
        <ul class="opo-list" *ngIf="opoSelections.length > 0">
          <li
            *ngFor="let opo of opoSelections"
            (click)="selectOpo(opo)"
            [ngClass]="
              opo.centerCode + '-' + opo.centerType === getOpoName()
                ? 'selected'
                : ''
            "
          >
            {{ opo.centerCode }}-{{ opo.centerType }}
          </li>
        </ul>
      </div>
      <button (click)="logout()" class="logout">
        <div><img src="/assets/logout.svg" /></div>
        <div>Logout</div>
      </button>
    </div>
  </button>
</div>
