<div [ngClass]="currentView + ' unos-container'">
  <div class="unos-header-top">
    <div class="unos-logo">
      <img src="/assets/unos.svg" alt="UNOS Logistics Logo" height="30" />
    </div>
    <app-profile></app-profile>
  </div>
  <app-appbar (mapOrListEvent)="switchToView($event)">
    <app-map *ngIf="currentView === 'map'"></app-map>
    <app-table *ngIf="currentView === 'list'"></app-table>
  </app-appbar>
</div>
