<ng-select
  class="filter"
  [(ngModel)]="selectedItem"
  [multiple]="multiple"
  bindLabel="name"
  bindValue="id"
  [closeOnSelect]="!multiple"
  [items]="options"
  (change)="onChange($event)"
  placeholder="{{ placeholder }}"
  addTagText="foobar"
  [clearSearchOnAdd]="multiple"
  [searchable]="searchable"
>
  <ng-template ng-option-tmp let-item="item" *ngIf="multiple">
    <div>
      <input [checked]="item.checked" type="checkbox" /> {{ item.name }}
    </div>
  </ng-template>
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngIf="items.length === 1">
      <span class="ng-value-label">{{ items[0].name }}</span>
    </div>
    <div class="ng-value" *ngIf="items.length >= 2">
      <span class="ng-value-label">{{ items.length }} Selected</span>
    </div>
  </ng-template>
</ng-select>
