import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Status } from "src/utils";
import { ShipmentFilters, ShipmentStore } from "./shipment.service";

export type StatusFilter = "all" | "active" | "delivered" | "canceled";

export interface FilterSelectModel {
  name: string;
  id: number | string;
}

export type FilterType = {
  [Property in ShipmentFilters]?: ((shipment: ShipmentStore) => boolean)[];
};

export interface FilterBehaviour {
  status: StatusFilter;
  filters: FilterType;
}

@Injectable()
export class FilterService {
  filters: FilterType = {};
  status: StatusFilter = "all";
  filterUpdate: BehaviorSubject<FilterBehaviour> = new BehaviorSubject({
    status: this.status,
    filters: this.filters,
  });

  constructor() {}

  filterShipments(shipments: ShipmentStore[]): ShipmentStore[] {
    return shipments.filter((shipment: ShipmentStore) =>
      this.statusFilter(shipment)
    );
  }

  clearAllFilters(sendEvent = true) {
    this.filters = {};
    if (sendEvent) {
      this.filterUpdate.next({
        status: this.status,
        filters: this.filters,
      });
    }
  }

  clearFilter(key: ShipmentFilters, sendEvent = true) {
    delete this.filters[key];
    if (sendEvent) {
      this.filterUpdate.next({
        status: this.status,
        filters: this.filters,
      });
    }
  }

  addRawFilter(
    property: ShipmentFilters,
    filter: (store: ShipmentStore) => boolean,
    sendEvent = true
  ) {
    if (typeof this.filters[property] !== "undefined") {
      // the question mark is there to appease typescripts flow sensitive typing
      this.filters[property]?.push(filter);
    } else {
      this.filters[property] = [filter];
    }
    if (sendEvent) {
      this.filterUpdate.next({
        status: this.status,
        filters: this.filters,
      });
    }
  }

  addFilter(
    property: ShipmentFilters,
    name: string | number,
    sendEvent = true
  ) {
    this.addRawFilter(
      property,
      (store) => {
        switch (property) {
          case "organ":
            return store.organ.id == name;
          default:
            return store[property] == name;
        }
      },
      sendEvent
    );
  }

  setStatusFilter(st: StatusFilter) {
    this.status = st;
    this.filterUpdate.next({
      status: this.status,
      filters: this.filters,
    });
  }

  statusFilter(shipment: ShipmentStore) {
    switch (this.status) {
      case "all":
        return true;
      case "active":
        return shipment.status.id == Status.ACTIVE;
      case "delivered":
        return shipment.status.id == Status.DELIVERED;
      case "canceled":
        return shipment.status.id > Status.DELIVERED;
    }
  }

  getFilter(): Observable<{
    updated: Date;
    filter: (shipments: ShipmentStore[]) => ShipmentStore[];
  }> {
    return this.filterUpdate.pipe(
      map(({ filters }) => {
        const fns: ((s: ShipmentStore[]) => ShipmentStore[])[] = [
          (shipments) =>
            shipments.filter((shipment) => this.statusFilter(shipment)),

          ...Object.entries(filters).map(
            ([_, fs]) =>
              (shipments: ShipmentStore[]) =>
                shipments.filter((shipment: ShipmentStore) =>
                  fs.some((f) => f(shipment))
                )
          ),
        ];

        return {
          filter: (shipments) =>
            fns.reduce((shipment, f) => f(shipment), shipments),
          updated: new Date(),
        };
      })
    );
  }
}
