<div class="row-details-container">
  <div class="location-container">
    <div class="location-header">From</div>
    <div class="location">
      <span class="opo">
        {{ shipment.originParsed.opo }}
      </span>
      <span *ngIf="shipment.originParsed.name">
        - {{ shipment.originParsed.name }}
      </span>
    </div>
    <div class="address">
      {{ shipment.originParsed.address }}
    </div>
  </div>
  <div class="location-container">
    <div class="location-header">To</div>
    <div class="location">
      <span class="opo">
        {{
          shipment.destinationParsed.address
            ? shipment.destinationParsed.opo
            : "To be defined"
        }}
      </span>
      <span *ngIf="shipment.destinationParsed.name">
        - {{ shipment.destinationParsed.name }}
      </span>
    </div>
    <div class="address">
      {{ shipment.destinationParsed.address }}
    </div>
  </div>
  <div class="update-container">
    <div *ngIf="isActive()" class="current-location">
      Currently: {{ shipment.currentLocation }}
    </div>
    <div *ngIf="isDelivered(); else updated" class="last-update border-padding">
      Delivered on:
      <span class="timestamp">{{ getDelivered() }}</span>
    </div>
    <ng-template #updated>
      <div class="last-update border-padding">
        Last updated:
        <span class="timestamp">{{ getLastUpdated() }}</span>
      </div>
    </ng-template>
  </div>
  <div *ngIf="shipment.gegoLink" class="gego-view">
    <a
      class="gego-link"
      href="{{ shipment.gegoLink }}"
      target="_blank"
      rel="noreferrer noopener"
    >
      <div class="gego-view">View with</div>
      <img src="/assets/gego.svg" />
    </a>
  </div>
</div>
