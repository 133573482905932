import { Provider } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthDemoService } from "./auth-demo.service";
import { AuthHttpService } from "./auth-http.service";
import { AuthService } from "./auth.service";

export { AuthService } from "./auth.service";

export const authProviders: Provider[] = environment.unosLoginApiBase
  ? [{ provide: AuthService, useClass: AuthHttpService }]
  : [{ provide: AuthService, useClass: AuthDemoService }];
