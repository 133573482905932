import { Injectable } from "@angular/core";
import { Observable, interval, map } from "rxjs";

import { environment } from "../../environments/environment";

@Injectable()
export class IntervalService {
  intervalObservable: Observable<number | undefined>;
  isStopped: boolean = false;
  constructor() {
    this.intervalObservable = interval(environment.tickRate).pipe(
      map((t) => (!this.isStopped ? t : undefined))
    );
  }

  getInterval() {
    return this.intervalObservable;
  }

  stopInterval() {
    this.isStopped = true;
  }
}
