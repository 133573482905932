<div class="unos-map-card state-{{ state }}">
  <div class="unos-map-marker--card">
    <ng-template [ngIf]="state === 'pin'">
      <div style="unos-map-marker--body">
        <img src="{{ organImage() }}" class="organ-image" />
      </div>
    </ng-template>
    <ng-template [ngIf]="state === 'brief'">
      <div class="card-header bordered">
        <div class="organ-thumbnail">
          <img src="{{ organImage() }}" />
        </div>
        <div class="card-header-info">
          <div class="card-donor-id">{{ shipment.donorId }}</div>
          <div class="card-organ">{{ shipment.organ.name }}</div>
        </div>
        <div class="card-status-button {{ statusClassFill() }}">
          {{ this.shipment.status.name }}
        </div>
      </div>
      <div class="card-info">
        <div class="card-battery">
          <span class="bold">{{ shipment.battery ?? "??" }}%</span>
          Current battery
        </div>
        <div class="destination">
          To:
          {{ shipment.destinationDescription ?? "UNKNOWN DESTINATION" }}
        </div>
        <div class="last-update">
          Last updated:
          <span class="timestamp">{{ getLastUpdated() }}</span>
        </div>
      </div>
      <div (click)="handleViewDetailsOpen()" class="view-details">
        View details
      </div>
    </ng-template>
    <ng-template [ngIf]="state === 'detailed'">
      <div class="card-header bordered">
        <div class="organ-thumbnail">
          <img src="{{ organImage() }}" />
        </div>
        <div class="card-header-info">
          <div class="card-donor-id">{{ shipment.donorId }}</div>
          <div class="card-organ">{{ shipment.organ.name }}</div>
        </div>
        <div class="card-status-button {{ statusClassFill() }}">
          {{ this.shipment.status.name }}
        </div>
      </div>
      <div class="card-info">
        <div class="bordered">
          <div>
            <span class="bold"> {{ shipment.battery ?? "??" }}% </span>
            Current battery
          </div>
          <div>
            Device:
            <span class="bold">{{ shipment.deviceName }}</span>
          </div>
        </div>
        <div class="shipment-details bordered">
          <div class="shipment-from">
            <div class="shipment-header">From</div>
            <div class="gego-link-container">
              <div>
                <span class="bold">{{ shipment.originParsed.opo }}</span>
                <span *ngIf="shipment.originParsed.name">
                  - {{ shipment.originParsed.name }}
                </span>
                <div class="shipment-address">
                  {{ shipment.originParsed.address }}
                </div>
              </div>
              <div *ngIf="shipment.gegoLink">
                <a
                  class="gego-link"
                  href="{{ shipment.gegoLink }}"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <div class="gego-view">View with</div>
                  <img src="/assets/gego.svg" />
                </a>
              </div>
            </div>
          </div>
          <div class="shipment-to">
            <div class="shipment-header">To</div>
            <div>
              <span class="bold">{{
                shipment.destinationParsed.address
                  ? shipment.destinationParsed.opo
                  : "To be defined"
              }}</span>
              <span *ngIf="shipment.destinationParsed?.name">
                - {{ shipment.destinationParsed.name }}
              </span>
            </div>
            <div class="shipment-address">
              {{ shipment.destinationParsed.address }}
            </div>
          </div>
        </div>
        <div
          *ngIf="isDelivered(); else updated"
          class="last-update border-padding"
        >
          Delivered on:
          <span class="timestamp">{{ getDelivered() }}</span>
        </div>
        <ng-template #updated>
          <div class="last-update border-padding">
            Last updated:
            <span class="timestamp">{{ getLastUpdated() }}</span>
          </div>
        </ng-template>
      </div>
      <div (click)="handleViewDetailsClose()" class="view-details">
        Close details
      </div>
    </ng-template>
    <div class="unos-map-marker--fill {{ statusClassFill() }}"></div>
  </div>
  <div class="unos-map-marker--arrow {{ statusClassArrow() }}"></div>
</div>
