import { Injectable } from "@angular/core";
import { of, EMPTY } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthDemoService implements AuthService {
  constructor() {}

  login(code?: string) {
    return of(true);
  }

  logout() {
    return EMPTY.subscribe();
  }

  isActive(): boolean {
    return true;
  }

  refresh() {}
}
