// various utilites/definition that don't fit elsewhere
import { DateTime } from "luxon";
import { environment } from "./environments/environment";

export enum Status {
  NEW = 1,
  ACTIVE = 2,
  DELIVERED = 3,
}
// any other status is counted as canceled

// organ listing (these are pulled from the api)
export enum Organ {
  HEART = 0,
  LEFT_LUNG = 2,
  RIGHT_LUNG = 4,
  LUNG_EN_BLOC = 6,
  LIVER = 8,
  LIVER_SPLIT_LEFT = 10,
  LIVER_SPLIT_RIGHT = 11,
  PANCREAS = 1,
  KIDNEY_LEFT = 3,
  KIDNEY_RIGHT = 5,
  KIDENY_EN_BLOCK = 7,
  INTENSTINE = 9,
}
export function statusSelect<T>(
  id: Status,
  {
    new: n,
    active,
    delivered,
    canceled,
  }: {
    new: T;
    active: T;
    delivered: T;
    canceled: T;
  }
): T {
  switch (id) {
    case Status.NEW:
      return n;
    case Status.ACTIVE:
      return active;
    case Status.DELIVERED:
      return delivered;
    default:
      return canceled;
  }
}

export function statusName(id: Status) {
  return statusSelect(id, {
    new: "new",
    active: "active",
    delivered: "delivered",
    canceled: "canceled",
  });
}

export function formatTime(time: DateTime): string {
  return time.toLocal().toFormat("ccc MMM d',' yyyy HH:mm ZZZZ");
}

// Some of our APIs return a UTC time without indicating the timezone
// JS treats this as local time, so we need to override that while not actually changing the time
export function forceUtcTimeZone(time: string): Date {
  return DateTime.fromISO(time, { zone: "utc" }).toJSDate();
}

export function getRelevantShipmentDateString(): string {
  return DateTime.utc().minus({ days: environment.lookbackDays }).toISO();
}
