import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";

import mapboxgl from "mapbox-gl";

import { environment } from "src/environments/environment";
import {
  LatLng,
  ShipmentService,
  ShipmentStore,
} from "src/app/services/shipment.service";
import { CardElem, CardState } from "../map-card/map-card.component";
import { FilterService } from "src/app/services/filter.service";
import { Status, statusSelect, statusName } from "src/utils";
import { GegoShipmentLocation } from "@services/gego/models";
import { UnosService } from "@services/unos";
import { PingPopupElem } from "../ping-popup/ping-popup.component";
import { DateTime } from "luxon";

class SatelliteControl {
  map?: mapboxgl.Map;
  container?: HTMLButtonElement;
  isSatellite: boolean = false;

  onAdd(map: mapboxgl.Map) {
    this.map = map;
    this.container = document.createElement("button");
    this.container.className = "mapboxgl-ctrl satellite-view";

    this.container.textContent = "Satellite view";
    this.container.addEventListener("click", () => {
      this.isSatellite = !this.isSatellite;
      if (this.isSatellite) {
        this.container!.textContent = "Map view";
        map.setStyle("mapbox://styles/mapbox/satellite-v9");
      } else {
        this.container!.textContent = "Satellite view";
        this.map!.setStyle("mapbox://styles/mapbox/streets-v11");
      }
    });
    return this.container;
  }

  onRemove() {
    this.container?.parentNode?.removeChild(this.container);
    this.map = undefined;
  }
}

class PingControl {
  map?: mapboxgl.Map;
  container: HTMLButtonElement;
  currentState: "detailed" | "lines" = "detailed";

  constructor(private callback: (state: "detailed" | "lines") => void) {
    this.container = document.createElement("button");
    this.container.className = "mapboxgl-ctrl satellite-view";

    callback(this.currentState);
  }

  onAdd(map: mapboxgl.Map) {
    this.map = map;
    this.container.textContent = "Line view";
    this.container.addEventListener("click", () => {
      this.currentState =
        this.currentState == "detailed" ? "lines" : "detailed";
      if (this.currentState == "detailed") {
        this.container.textContent = "Line view";
      } else {
        this.container.textContent = "Detailed view";
      }
      this.callback(this.currentState);
    });

    return this.container;
  }

  onRemove() {
    this.container?.parentNode?.removeChild(this.container);
    this.map = undefined;
  }
}

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit, OnDestroy {
  storeSubcription: Subscription | null = null;
  map: mapboxgl.Map | null = null;
  popups: mapboxgl.Popup[] = [];
  cards: CardElem[] = [];
  focusedShipmentId: number | null = null;
  history: { zoom: number; center: [number, number] } | null = null;
  filterSubscription: Subscription | null = null;
  resize: BehaviorSubject<{}> = new BehaviorSubject<{}>({});
  resizeSub: Subscription | null = null;
  observer: ResizeObserver | null = null;
  lastShipments: ShipmentStore[] | null = null;
  canViewShipments: boolean = true;
  viewState: "detailed" | "lines" = "detailed";
  isEmpty: boolean = false;

  @ViewChild("map") mapElement!: ElementRef<Element>;

  constructor(
    private shipmentService: ShipmentService,
    private filterService: FilterService,
    private unosService: UnosService,
    private host: ElementRef
  ) {}

  ngOnInit(): void {
    mapboxgl.accessToken = environment.mapboxKey;
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-95.7129, 37.0902],
      zoom: 3,
      minZoom: 2.75,
      maxPitch: 0,
    });

    this.map.addControl(new SatelliteControl(), "bottom-left");
    this.map.addControl(
      new PingControl((state) => this.handlePingState(state)),
      "bottom-left"
    );

    this.map.addControl(new mapboxgl.NavigationControl(), "bottom-left");

    this.map.once("load", async () => {
      this.map!.resize();
      this.prepareMap(this.map!);

      this.unosService.unosOpo.subscribe((_opo) => {
        this.canViewShipments = false;
      });

      this.storeSubcription = this.shipmentService
        .getFilteredStore()
        .subscribe((shipments) => {
          if (shipments.opoUpdate) {
            this.canViewShipments = true;
          }
          this.lastShipments = shipments.store;
          this.storeUpdate(shipments.store);
          if (shipments.filterUpdate) {
            this.zoomToFit(shipments.store);
          }
          this.isEmpty = shipments.loaded && shipments.store.length == 0;
        });
      this.filterSubscription = this.filterService
        .getFilter()
        .subscribe((_) => this.resetHistory());

      this.map!.on("styledata", async () => {
        await this.prepareMap(this.map!);
        this.lastShipments && this.updateLayers(this.map!, this.lastShipments);
      });
      this.map!.on("click", ["points", "specific-points"], (e) => {
        const feature = e.features;
        if (!feature) {
          return;
        }
        const reportTime = feature[0].properties?.reportTime;
        const coords = (feature[0].geometry as any).coordinates;
        const popup = document.createElement(
          "pingpopup-element"
        ) as PingPopupElem;
        if (reportTime) {
          popup.reportTime = DateTime.fromJSDate(new Date(reportTime));
        }
        popup.coords = coords;
        new mapboxgl.Popup({
          closeOnClick: true,
          closeOnMove: true,
          maxWidth: "300px",
        })
          .setDOMContent(popup)
          .setLngLat(coords)
          .addTo(this.map!);
      });

      // change pointer to indicate you can click!
      this.map!.on("mouseenter", ["points", "specific-points"], () => {
        this.map!.getCanvas().style.cursor = "pointer";
      });
      this.map!.on("mouseleave", ["points", "specific-points"], () => {
        this.map!.getCanvas().style.cursor = "";
      });
    });
  }

  ngOnDestroy(): void {
    this.storeSubcription && this.storeSubcription.unsubscribe();
    this.storeSubcription = null;
    this.filterSubscription && this.filterSubscription.unsubscribe();
    this.filterSubscription = null;
    this.resizeSub && this.resizeSub.unsubscribe();
    this.resizeSub = null;
    this.observer?.unobserve(this.host.nativeElement.querySelector("#map"));
  }

  handlePingState(state: "detailed" | "lines") {
    this.viewState = state;
    if (this.lastShipments) {
      this.updateLayers(this.map!, this.lastShipments);
    }
  }

  async prepareMap(map: mapboxgl.Map) {
    // will initially be called twice, add a guard to make sure we don't rerun
    if (map.getSource("points-data")) {
      return;
    }

    map.addSource("points-data", {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
    });
    map.addSource("lines-data", {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
    });
    map.addSource("specific-lines-data", {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
    });
    map.addSource("specific-points-data", {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
    });
    await Promise.all(
      this.getAllImages().map((image) =>
        this.loadImage(`/assets/${image}.png`, image)
      )
    );

    map.addLayer({
      id: "lines",
      type: "line",
      source: "lines-data",
      paint: {
        "line-color": ["get", "color"],
        "line-width": 6,
        "line-dasharray": ["get", "dash"],
      },
    });

    map.addLayer({
      id: "specific-lines",
      type: "line",
      source: "specific-lines-data",
      layout: {
        visibility: "none",
      },
      paint: {
        "line-color": ["get", "color"],
        "line-width": 6,
        "line-dasharray": ["get", "dash"],
      },
    });
    map.addLayer({
      id: "points",
      type: "symbol",
      source: "points-data",
      layout: {
        "icon-image": ["get", "image"],
        "icon-allow-overlap": true,
        "icon-size": 0.75,
      },
    });
    map.addLayer({
      id: "specific-points",
      type: "symbol",
      source: "specific-points-data",
      layout: {
        "icon-image": ["get", "image"],
        "icon-allow-overlap": true,
        "icon-size": 0.75,
        visibility: "none",
      },
    });
  }

  getAllImages() {
    return ([] as string[]).concat(
      ...["active", "new", "canceled", "delivered"].map((suffix) =>
        ["marker", "current", "destination", "ping"].map(
          (image) => `${image}-${suffix}`
        )
      )
    );
  }

  storeUpdate(store: ShipmentStore[]) {
    if (this.map === null) {
      return;
    }
    this.updateLayers(this.map, store);
    this.updateMarkers(this.map, store);
  }

  updateLayers(map: mapboxgl.Map, store: ShipmentStore[]) {
    let points: ReturnType<typeof this.createPoint>[] = [];
    let lines: ReturnType<typeof this.shipmentLines> = [];

    for (let shipment of store) {
      const suffix = this.statusSuffix(shipment.status.id);
      const currentPoints = [];
      if (shipment.destinationLatLng) {
        currentPoints.push(
          this.createPoint(shipment.destinationLatLng, {
            image: `destination-${suffix}`,
          })
        );
      }
      if (shipment.origin) {
        currentPoints.push(
          this.createPoint(shipment.origin, {
            image: `marker-${suffix}`,
          })
        );
      }
      if (shipment.status.id !== Status.DELIVERED) {
        currentPoints.push(
          this.createPoint(shipment.lastAt, {
            image: `current-${suffix}`,
          })
        );
      }

      const currentLines = this.shipmentLines(shipment);
      const pings = this.shipmentPings(shipment);

      lines = lines.concat(currentLines);
      points = points.concat(currentPoints);
      points = points.concat(pings);

      if (shipment.id == this.focusedShipmentId) {
        (map.getSource("specific-points-data") as any).setData({
          type: "FeatureCollection",
          features: currentPoints.concat(pings),
        });
        (map.getSource("specific-lines-data") as any).setData({
          type: "FeatureCollection",
          features: currentLines,
        });
      }
    }

    (map.getSource("points-data") as any).setData({
      type: "FeatureCollection",
      features: points,
    });
    (map.getSource("lines-data") as any).setData({
      type: "FeatureCollection",
      features: lines,
    });
  }

  shipmentPings(shipment: ShipmentStore) {
    const suffix = this.statusSuffix(shipment.status.id);
    if (this.viewState == "detailed") {
      return shipment.locationHistory
        .slice(1)
        .map((current: GegoShipmentLocation) =>
          this.createPoint(
            {
              lng: current.Longitude,
              lat: current.Latitude,
            },
            {
              image: `ping-${suffix}`,
              reportTime: current.ReportTime,
            }
          )
        );
    } else {
      return [];
    }
  }

  shipmentLines(shipment: ShipmentStore) {
    const lines = [];
    const history = shipment.locationHistory.map((location) => [
      location.Longitude,
      location.Latitude,
    ]);
    lines.push({
      type: "Feature",
      properties: {
        color: this.statusColor(shipment.status.id),
        dash: [],
      },
      geometry: {
        type: "LineString",
        coordinates: [
          // history is from most recent to last, so we have
          // to draw the lines "backwards"
          ...history,
          [shipment.origin.lng, shipment.origin.lat],
        ],
      },
    });

    if (shipment.lastAt && shipment.destinationLatLng) {
      lines.push({
        type: "Feature",
        properties: {
          color: this.statusColor(shipment.status.id),
          dash: [1, 1],
        },
        geometry: {
          type: "LineString",
          coordinates: [
            [shipment.lastAt.lng, shipment.lastAt.lat],
            [shipment.destinationLatLng.lng, shipment.destinationLatLng.lat],
          ],
        },
      });
    }

    return lines;
  }

  updateMarkers(_: mapboxgl.Map, store: ShipmentStore[]) {
    for (let [marker, card] of this.popups.map((m, i) => [m, this.cards[i]])) {
      (card as CardElem).remove();
      marker.remove();
    }
    this.cards = [];
    this.popups = [];
    for (let shipment of store) {
      if (shipment.lastAt == null) {
        continue;
      }

      const card = document.createElement("mapcard-element") as CardElem;
      card.shipment = shipment;
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        closeOnMove: false,
        className: "mapcard",
      })
        .setMaxWidth("400px")
        .setDOMContent(card)
        .setLngLat(card.shipment.lastAt)
        .addTo(this.map!);

      card.addEventListener("stateOutput", (ev) => {
        if (!this.canViewShipments) {
          return;
        }
        switch ((ev as CustomEvent).detail as CardState) {
          case "pin":
            this.handleCardPin(card);
            break;
          case "hovered":
            this.handleCardHovered(card, popup);
            break;
          case "brief":
            this.handleCardBrief(card, popup);
            break;
          case "detailed":
            this.handleCardDetailed(card, popup, shipment);
            break;
        }
      });
      if (shipment.id == this.focusedShipmentId) {
        card.state = "detailed";
      }

      this.cards.push(card);
      this.popups.push(popup);
    }
  }

  handleCardHovered(_: CardElem, popup: mapboxgl.Popup) {
    popup.remove();
    popup.addTo(this.map!);
  }

  handleCardPin(el: CardElem) {
    el.state = "pin";
    this.resetHistory();
  }

  handleCardBrief(card: CardElem, _: mapboxgl.Popup) {
    for (let resetCard of this.cards) {
      if (card.shipment.id == resetCard.shipment.id) {
        continue;
      }
      resetCard.state = "pin";
    }
    card.state = "brief";
    setTimeout(() => this.handleClip(card));
  }

  handleCardDetailed(
    card: CardElem,
    popup: mapboxgl.Popup,
    shipment: ShipmentStore
  ) {
    this.focusedShipmentId = shipment.id;

    for (let resetCard of this.cards) {
      if (resetCard.shipment.id == card.shipment.id) {
        continue;
      }
      resetCard.state = "pin";
      resetCard.style.display = "none";
    }

    if (this.map) {
      this.focusedShipmentId = shipment.id;

      const { lng, lat } = this.map.getCenter();
      this.history = {
        zoom: this.map.getZoom(),
        center: [lng, lat],
      };

      this.map.setLayoutProperty("lines", "visibility", "none");
      this.map.setLayoutProperty("points", "visibility", "none");

      const suffix = this.statusSuffix(shipment.status.id);

      const p1 = shipment.destinationLatLng
        ? this.createPoint(shipment.destinationLatLng, {
            image: `destination-${suffix}`,
          })
        : null;
      const p2 = shipment.origin
        ? this.createPoint(shipment.origin, {
            image: `marker-${suffix}`,
          })
        : null;
      const pings = this.shipmentPings(shipment);
      const lines = this.shipmentLines(shipment)!;

      card.state = "detailed";
      (this.map.getSource("specific-points-data") as any).setData({
        type: "FeatureCollection",
        features: [p1, p2].filter((x) => x !== null).concat(pings),
      });
      (this.map.getSource("specific-lines-data") as any).setData({
        type: "FeatureCollection",
        features: lines,
      });
      this.map.setLayoutProperty("specific-points", "visibility", "visible");
      this.map.setLayoutProperty("specific-lines", "visibility", "visible");

      this.map.flyTo({
        zoom: 14,
        center: popup.getLngLat(),
      });
      this.map.once("moveend", () => this.handleClip(card));
    }
  }

  handleClip(card: CardElem) {
    const container = this.mapElement.nativeElement.getBoundingClientRect();

    // peel back a layer to the actual position element
    const child = card.children[0].getBoundingClientRect();

    const outOfBounds = [
      child.top < container.top, // clips up
      child.right > container.right, // clips right
      child.left < container.left, // clips left
      child.bottom > container.bottom, // clips bottom
    ];

    if (outOfBounds.some((x) => x)) {
      const { lat, lng } = this.map!.getCenter();
      const lnglat = outOfBounds.reduce(
        (c: [number, number], x, i) => {
          if (x) {
            // select the direction to pan the map based
            // on what side we are clipping on
            const [lng, lat] = [
              [0, -0.00002],
              [0.000035, 0],
              [-0.000035, 0],
              [0, 0.00002],
            ][i];

            const zoom = this.getZoomCoefficient();
            return [c[0] + lng * zoom, c[1] - lat * zoom];
          } else {
            return c;
          }
        },
        [lng, lat]
      ) as [number, number];

      this.map!.flyTo({
        center: lnglat,
      });
    }
  }

  resetHistory() {
    if (this.map && this.history) {
      this.map.flyTo(this.history);
      this.history = null;
      this.focusedShipmentId = null;
      for (let card of this.cards) {
        card.style.display = "block";
      }
      this.map.setLayoutProperty("lines", "visibility", "visible");
      this.map.setLayoutProperty("points", "visibility", "visible");
      this.map.setLayoutProperty("specific-points", "visibility", "none");
      this.map.setLayoutProperty("specific-lines", "visibility", "none");
    }
  }

  zoomToFit(store: ShipmentStore[]) {
    if (store.length == 0) {
      return;
    }

    const lats = ([] as (number | null)[])
      .concat(
        ...store.map((x) => [
          x.destinationLatLng?.lat ?? null,
          x.origin.lat,
          x.lastAt.lat,
        ])
      )
      .filter((x) => x !== null) as number[];
    const lngs = ([] as (number | null)[])
      .concat(
        ...store.map((x) => [
          x.destinationLatLng?.lng ?? null,
          x.origin.lng,
          x.lastAt.lng,
        ])
      )
      .filter((x) => x !== null) as number[];
    this.map!.fitBounds(
      [
        [Math.min(...lngs), Math.min(...lats)],
        [Math.max(...lngs), Math.max(...lats)],
      ],
      {
        padding: 100,
      }
    );
  }

  createPoint(point: LatLng, properties: {}) {
    return {
      type: "Feature",
      properties,
      geometry: {
        type: "Point",
        coordinates: [point.lng, point.lat],
      },
    };
  }

  statusColor(statusId: number): string {
    return statusSelect(statusId, {
      new: "#000000",
      active: "#006FBA",
      delivered: "#689E36",
      canceled: "#B753D3",
    });
  }

  statusSuffix(statusId: number): string {
    return statusName(statusId);
  }

  loadImage(src: string, name: string) {
    return new Promise((resolve, reject) => {
      this.map?.loadImage(src, (error, image) => {
        if (error || !image) {
          reject(error);
        } else {
          this.map?.addImage(name, image);
          resolve(name);
        }
      });
    });
  }

  getZoomCoefficient(): number {
    // the zoom levels in general follows an exponential pattern
    // in terms of pixels/lat. This is due to the quad tree
    // implementation and how a zoom level is one level of the
    // quad tree.
    return 2 ** Math.abs(Math.min(this.map!.getZoom(), 23) - 22);
  }
}
