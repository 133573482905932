import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { tap, of, Observable } from "rxjs";
import { environment } from "../../environments/environment";

const API_BASE = "https://api.mapbox.com/geocoding/v5/mapbox.places";
const LAT_EPSILON = 0.002;
const LNG_EPSILON = 0.002;

interface Geocode {
  type: string;
  query: [number, number];
  features: [
    {
      place_name: string;
    }
  ];
}

@Injectable()
export class GeocodingService {
  geocodeCache: {
    coords: [number, number];
    result: Geocode;
  }[] = [];

  constructor(private http: HttpClient) {}

  lookup(coords: [number, number]) {
    return this.geocodeCache.find(
      ({ coords: possible }) =>
        Math.abs(possible[0] - coords[0]) <= LNG_EPSILON &&
        Math.abs(possible[1] - coords[1]) <= LAT_EPSILON
    );
  }

  insert(coords: [number, number], result: Geocode) {
    this.geocodeCache.push({
      coords,
      result,
    });
  }

  fromLngLat(coords: [number, number]): Observable<Geocode> {
    const lookup = this.lookup(coords);
    if (lookup) {
      return of(lookup.result);
    }
    return this.http
      .get<Geocode>(
        `${API_BASE}/${coords[0]},${coords[1]}.json?access_token=${environment.mapboxKey}`
      )
      .pipe(tap((x) => this.insert(coords, x)));
  }
}
