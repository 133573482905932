import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { MapComponent } from "./components/map/map.component";
import { UnauthorizedComponent } from "./components/unauthorized/unauthorized.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { AppbarComponent } from "./components/appbar/appbar.component";
import { FilterSelectComponent } from "./components/filter-select/filter-select.component";
import { MapCardComponent } from "./components/map-card/map-card.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { TableComponent } from "./components/table/table.component";

import { authProviders } from "@services/auth";
import { gegoProviders } from "@services/gego";
import { unosProviders } from "@services/unos";
import { IntervalService } from "./services/interval.service";
import { ShipmentService } from "./services/shipment.service";
import { FilterService } from "./services/filter.service";

import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";
import { RowDetailsComponent } from "./components/row-details/row-details.component";
import { EmptyPopupComponent } from "./components/empty-popup/empty-popup.component";
import { LoadingPopupComponent } from "./components/loading-popup/loading-popup.component";
import { GeocodingService } from "./services/geocoding.service";
import { PingPopupComponent } from "./components/ping-popup/ping-popup.component";

import { httpInterceptorProviders } from "./interceptors";
import { Routes, RouterModule } from "@angular/router";
import { MainPageComponent } from "./components/main-page/main-page.component";

import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";

const routes: Routes = [
  { path: "", component: MainPageComponent },
  { path: "**", component: MainPageComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    UnauthorizedComponent,
    PageNotFoundComponent,
    AppbarComponent,
    FilterSelectComponent,
    MapCardComponent,
    ProfileComponent,
    TableComponent,
    RowDetailsComponent,
    EmptyPopupComponent,
    LoadingPopupComponent,
    PingPopupComponent,
    MainPageComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    DataTablesModule,
    RouterModule.forRoot(routes),
  ],
  providers: [
    authProviders,
    gegoProviders,
    unosProviders,
    FilterService,
    ShipmentService,
    IntervalService,
    GeocodingService,
    httpInterceptorProviders,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
