<div class="app-navigation">
  <div class="date-container">
    <div class="last-date-header">Current device locations</div>
    <div>
      <span>Last updated:</span>&nbsp;
      <span class="last-date">{{ lastUpdated }}</span>
    </div>
  </div>
  <div class="view-switch-container">
    <div class="view-switch">
      <button
        (click)="viewSwitch('map')"
        [ngClass]="(mapOrList === 'map' ? 'selected' : 'unselected') + ' map'"
      >
        Map&nbsp;view
      </button>
      <button
        (click)="viewSwitch('list')"
        [ngClass]="(mapOrList === 'list' ? 'selected' : 'unselected') + ' list'"
      >
        List&nbsp;view
      </button>
    </div>
  </div>

  <div class="filter-status-container">
    <ul class="filter-status">
      <li [ngClass]="activeStatus === 'all' ? 'all current' : 'all'">
        <button (click)="filterOn('all')">All</button>
      </li>
      <li [ngClass]="activeStatus === 'active' ? 'active current' : 'active'">
        <button (click)="filterOn('active')">Active</button>
      </li>
      <li
        [ngClass]="
          activeStatus === 'delivered' ? 'delivered current' : 'delivered'
        "
      >
        <button (click)="filterOn('delivered')">Delivered</button>
      </li>
      <li
        [ngClass]="
          activeStatus === 'canceled' ? 'canceled current' : 'canceled'
        "
      >
        <button (click)="filterOn('canceled')">Canceled</button>
      </li>
    </ul>
  </div>
  <button
    (click)="expandDrawer()"
    [ngClass]="
      'more-filters ' +
      (drawerState === 'shown' ? 'filters-show' : 'filters-hidden')
    "
  >
    <div class="filter-icon">
      <img src="/assets/icon-filter.svg" />
    </div>
    <div class="more-filters-button">
      {{ drawerState === "hidden" ? "More" : "Hide" }}&nbsp;filters
    </div>
  </button>
</div>
<app-empty-popup *ngIf="isEmpty && isLoaded"></app-empty-popup>
<div class="drawered-container">
  <div class="drawer-content">
    <app-loading-popup *ngIf="!isLoaded"></app-loading-popup>
    <ng-content></ng-content>
  </div>
  <div class="pin">
    <div class="filter-drawer-container" [attr.data-state]="drawerState">
      <button (click)="expandDrawer()" class="filter-arrow">
        <div class="arrow-text">
          {{ drawerState === "hidden" ? "&#9664;" : "&#9654;" }}
        </div>
      </button>
      <div class="filters">
        <div class="filters-header">
          <div class="filter-icon">
            <img src="/assets/icon-filter.svg" />
          </div>
          <div class="filter-text">More&nbsp;filters</div>
          <button (click)="expandDrawer()" class="filter-close">Close</button>
        </div>
        <div class="filter-section">
          <div class="filter-header">Organs</div>
          <div class="filter-selector">
            <app-filter-select
              [multiple]="false"
              property="donorId"
              placeholder="By donor ID"
            ></app-filter-select>
          </div>
          <div class="filter-selector">
            <app-filter-select
              [searchable]="false"
              property="organ"
              placeholder="By organ"
            ></app-filter-select>
          </div>
        </div>
        <div class="filter-section">
          <div class="filter-header">Locations</div>
          <div class="filter-selector">
            <app-filter-select
              property="destination"
              placeholder="By destination"
            ></app-filter-select>
          </div>
          <div class="filter-selector">
            <app-filter-select
              property="deviceName"
              placeholder="By device name"
            ></app-filter-select>
          </div>
        </div>
        <div class="filter-clear">
          <button (click)="clearAll()">Clear all filters</button>
        </div>
      </div>
    </div>
  </div>
</div>
