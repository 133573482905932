import { Component, Input, OnInit } from "@angular/core";
import { NgElement, WithProperties } from "@angular/elements";
import { DateTime } from "luxon";
import { GeocodingService } from "src/app/services/geocoding.service";
import { formatTime } from "src/utils";

export type PingPopupElem = NgElement &
  WithProperties<{
    coords: [number, number];
    reportTime: DateTime;
  }>;

@Component({
  selector: "app-ping-popup",
  templateUrl: "./ping-popup.component.html",
  styleUrls: ["./ping-popup.component.scss"],
})
export class PingPopupComponent {
  _coords: [number, number] | null = null;

  @Input() set coords(val: [number, number]) {
    this._coords = val;
    this.geocodeService.fromLngLat(this._coords).subscribe((geocode) => {
      this.placeName = geocode.features[0].place_name;
      this.geocodeLoaded = true;
    });
  }

  @Input() reportTime?: DateTime;
  geocodeLoaded: boolean = false;
  placeName: string | null = null;

  constructor(private geocodeService: GeocodingService) {}

  getTime() {
    return (this.reportTime && formatTime(this.reportTime)) ?? "";
  }
}
