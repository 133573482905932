import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IntervalService } from "@services/interval.service";
import { tap, of, map, catchError, take, Subscription, delay } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpCancelService } from "../http-cancel.service";

import { AuthService } from "./auth.service";
import { AuthResponse } from "./models/auth-response";

@Injectable({
  providedIn: "root",
})
export class AuthHttpService implements AuthService {
  private intervalSubscription!: Subscription;
  constructor(
    private http: HttpClient,
    private httpCancelService: HttpCancelService,
    private intervalService: IntervalService
  ) {
    this.setupInterval();
  }

  login(code?: string) {
    return this.requestAccessToken(code).pipe(
      tap((response) => {
        let authResponse = response.body;
        if (authResponse?.location != null && authResponse?.location !== "") {
          window.location.assign(authResponse?.location);
        } else {
          localStorage.setItem("is-logged-in", "true");
        }
      }),
      map((_) => true),
      catchError((_) => of(false))
    );
  }

  private requestAccessToken(code?: string) {
    let codeFragment = code != null ? `?code=${code}` : "";
    let url: string = `${environment.unosLoginApiBase}/login${codeFragment}`;

    return this.http
      .get<AuthResponse>(url, { observe: "response" })
      .pipe(tap((response) => console.log(response)));
  }

  logout() {
    this.httpCancelService.cancelPendingRequests();
    return this.apiRevoke().subscribe((_) =>
      this.login().pipe(take(1)).subscribe()
    );
  }

  private apiRevoke() {
    return this.http
      .post(`${environment.unosLoginApiBase}/revoke`, null)
      .pipe(tap((_) => localStorage.removeItem("is-logged-in")));
  }

  isActive(): boolean {
    const isLoggedIn = localStorage.getItem("is-logged-in");
    return isLoggedIn === "true";
  }

  refresh() {
    if (this.isActive()) {
      this.http
        .post(`${environment.unosLoginApiBase}/refresh`, null, {
          observe: "response",
        })
        .subscribe();
    }
  }

  private setupInterval() {
    this.intervalSubscription = this.intervalService
      .getInterval()
      // We'll delay the auth refresh call so it doesn't hit the backend at the same time as the data calls
      .pipe(delay(environment.tickRate / 2))
      .subscribe((t) => typeof t !== "undefined" && this.refresh());
  }
}
