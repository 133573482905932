import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-empty-popup",
  templateUrl: "./empty-popup.component.html",
  styleUrls: ["./empty-popup.component.scss"],
})
export class EmptyPopupComponent {
  constructor() {}
}
