import { HttpClient } from "@angular/common/http";
import { Injectable, Provider } from "@angular/core";
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  map,
  Observable,
  of,
  share,
} from "rxjs";
import { getRelevantShipmentDateString, Status } from "src/utils";
import { environment } from "../../../environments/environment";
import {
  OrganLookup,
  UnosOpo,
  UnosOpoAccess,
  UnosShipment,
  UnosStatusLookup,
  UnosUserAccess,
} from "./models";
import { UnosService } from "./unos.interface";

interface Wrapper<T> {
  Value: T;
}

@Injectable()
export class UnosHttpService implements UnosService {
  unosOpo = new BehaviorSubject<[UnosOpo | null, UnosUserAccess] | null>(null);
  private userAccess: UnosUserAccess | null = null;
  private center: UnosOpo | null = null;

  constructor(private http: HttpClient) {
    this.getUserAccess()?.subscribe((x) => {
      this.userAccess = x;
      const opos = this.userAccess?.OposUserHasAccessTo;
      if (opos.length > 0) {
        this.center = {
          centerCode: opos[0].CenterCode,
          centerType: opos[0].CenterType,
        };
        this.unosOpo.next([this.center, this.userAccess]);
      } else {
        this.unosOpo.next([null, this.userAccess]);
      }
    });
  }

  getCenterHeaders(): {
    headers: { [header: string]: string };
  } {
    const headers: { [T: string]: string } = {};

    if (this.center) {
      headers["X-Center-Code"] = this.center.centerCode;
      headers["X-Center-Type"] = this.center.centerType;
    }

    return { headers };
  }

  private getUrl<T>(
    endpoint: string,
    requiresCenter: boolean = true
  ): Observable<T> {
    if (requiresCenter && this.center === null) {
      return EMPTY;
    }
    return this.http
      .get<Wrapper<T>>(
        `${environment.unosApiBase}${endpoint}`,
        this.getCenterHeaders()
      )
      .pipe(
        map((wrapped) => wrapped.Value),
        share()
      );
  }

  getAllDeviceShipments(): Observable<UnosShipment[]> {
    return combineLatest([
      this.getNewDeviceShipments() ?? EMPTY,
      this.getActiveDeviceShipments() ?? EMPTY,
      this.getDeliveredDeviceShipments() ?? EMPTY,
      this.getCanceledDeviceShipments() ?? EMPTY,
    ]).pipe(
      map((arr) => arr.flat().filter((shipment) => shipment.VendorShipmentId))
    );
  }

  private getNewDeviceShipments() {
    return this.getUrl<UnosShipment[]>(
      `/device-shipments?$filter=DeviceShipmentStatusId eq ${Status.NEW}`
    );
  }

  private getActiveDeviceShipments() {
    return this.getUrl<UnosShipment[]>(
      `/device-shipments?$filter=DeviceShipmentStatusId eq ${Status.ACTIVE}`
    );
  }

  private getDeliveredDeviceShipments() {
    return this.getUrl<UnosShipment[]>(
      `/device-shipments?$filter=DeviceShipmentStatusId eq ${
        Status.DELIVERED
      } and DeliveredDateTimeUtc gt datetime'${getRelevantShipmentDateString()}'`
    );
  }

  private getCanceledDeviceShipments() {
    return this.getUrl<UnosShipment[]>(
      `/device-shipments?$filter=DeviceShipmentStatusId gt ${
        Status.DELIVERED
      } and CanceledDateTimeUtc gt datetime'${getRelevantShipmentDateString()}'`
    );
  }

  getSingleDeviceShipment(id: string) {
    return this.getUrl<UnosShipment>(`/device-shipments/${id}`);
  }

  getOrganLookup() {
    return this.getUrl<OrganLookup[]>("/lookups/package-organ", false)!;
  }

  getStatusLookup() {
    return this.getUrl<UnosStatusLookup[]>(
      "/lookups/device-shipment-status",
      false
    )!;
  }

  getUserAccess() {
    return this.getUrl<UnosUserAccess>("/user-access", false)!;
  }

  setOpo(opo: UnosOpo | null) {
    if (!this.userAccess) {
      throw new Error("Invalid User");
    }
    if (!this.verifyOpo(this.userAccess.OposUserHasAccessTo, opo)) {
      throw new Error("Invalid OPO");
    }

    this.center = opo;
    this.unosOpo.next([opo, this.userAccess]);
  }

  verifyOpo(accessibleOpos: UnosOpoAccess[], opo: UnosOpo | null): boolean {
    return (
      opo !== null &&
      accessibleOpos.some(
        ({ CenterCode: centerCode, CenterType: centerType }) =>
          opo.centerCode == centerCode && opo.centerType == centerType
      )
    );
  }
}

export const unosProviders: Provider[] = [
  { provide: UnosService, useClass: UnosHttpService },
];
