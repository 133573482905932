import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import {
  combineLatestAll,
  combineLatestWith,
  debounceTime,
  defaultIfEmpty,
  distinct,
  filter,
  map,
  mergeMap,
  share,
  tap,
} from "rxjs/operators";
import { forceUtcTimeZone, Organ, Status, statusName } from "src/utils";
import { FilterService, FilterSelectModel } from "./filter.service";
import { GegoService } from "@services/gego";
import { UnosService } from "@services/unos";
import {
  GegoDevice,
  GegoShipment,
  GegoShipmentLocation,
} from "@services/gego/models";
import {
  OrganLookup,
  UnosStatusLookup,
  UnosShipment,
} from "@services/unos/models";
import { isNotNullOrUndefined } from "@microsoft/applicationinsights-core-js";

export interface LatLng {
  lat: number;
  lng: number;
}

export interface ShipmentFilterables {
  status: { id: Status; name: string };
  organ: { id: Organ; name: string };
  destination: string;
  deviceName: string;
  donorId: string;
}
export type ShipmentFilters = keyof ShipmentFilterables;

export interface LocationName {
  opo?: string;
  name?: string;
  address: string;
}

export interface ShipmentLatLngs {
  id: number;
  deviceName: string;
  battery: number | null;
  lastUpdated: Date;
  gegoLink: string;
  currentLocation: string | null;
  destinationDescription: string | null;
  destinationParsed: LocationName;
  originDescription: string;
  originParsed: LocationName;
  lastAt: LatLng;
  origin: LatLng;
  destinationLatLng: LatLng | null;
  locationHistory: GegoShipmentLocation[];
  deliveredDate?: Date;
  canceledDate?: Date;
}

export type ShipmentStore = ShipmentFilterables & ShipmentLatLngs;

const INITIAL_STATE = {
  organs: [],
  statuses: [],
  store: [],
  loaded: false,
  opoUpdate: true,
  updated: new Date(),
};

@Injectable()
export class ShipmentService {
  organLookup: OrganLookup[] | null = null;
  organLookupObs: Observable<OrganLookup[]>;
  statusLookup: UnosStatusLookup[] | null = null;
  statusLookupObs: Observable<UnosStatusLookup[]>;

  shipmentStore = new BehaviorSubject<{
    organs: OrganLookup[];
    statuses: UnosStatusLookup[];
    store: ShipmentStore[];
    loaded: boolean;
    updated: Date;
    opoUpdate: boolean;
  }>(INITIAL_STATE);

  constructor(
    private gegoService: GegoService,
    private unosService: UnosService,
    private filterService: FilterService
  ) {
    this.organLookupObs = this.unosService.getOrganLookup().pipe(
      map((x) => {
        return x?.map((organ) => {
          const desc = `${organ.Description[0]}${organ.Description.substring(
            1
          ).toLowerCase()}`;
          return {
            ...organ,
            Description: desc,
          };
        });
      })
    );
    this.statusLookupObs = this.unosService.getStatusLookup();

    this.unosService.unosOpo.subscribe((_) => {
      this.shipmentStore.next({ ...INITIAL_STATE, opoUpdate: true });
      this.refresh(true);
    });
  }

  refresh(opoUpdate = false) {
    const shipments = this.unosService.getAllDeviceShipments();
    if (shipments == null) {
      return;
    }
    const gegoShipments = shipments.pipe(
      mergeMap((shipments) =>
        shipments.map((shipment) => shipment.VendorShipmentId)
      ),
      filter((vendorId) => typeof vendorId !== "undefined"),
      distinct((vendorId) => vendorId),
      map((vendorId) => this.gegoService.getSingleShipment(vendorId!)),
      share(),
      combineLatestAll(),
      map((shipments) =>
        shipments.filter(
          (shipment) =>
            isNotNullOrUndefined(shipment.ShipmentId) &&
            shipment.Locations.length > 0
        )
      )
    );
    const gegoDevices = gegoShipments.pipe(
      mergeMap((gegos) =>
        gegos.map((gego) => this.gegoService.getSingleDeviceV2(gego.DeviceId))
      ),
      combineLatestAll()
    );

    shipments
      .pipe(
        combineLatestWith(
          gegoShipments,
          gegoDevices,
          this.statusLookupObs,
          this.organLookupObs
        ),
        defaultIfEmpty([null, null, null, null, null])
      )
      .subscribe({
        next: ([unos, gego, devices, statuses, organs]) => {
          this.organLookup = organs ?? null;
          this.statusLookup = statuses ?? null;
          this.shipmentStore.next({
            organs: this.organLookup ?? [],
            statuses: this.statusLookup ?? [],
            store: this.mergeShipments(gego ?? [], devices ?? [], unos ?? []),
            loaded: true,
            updated: new Date(),
            opoUpdate: opoUpdate,
          });
        },
      });
  }

  mergeShipments(
    gego: GegoShipment[],
    devices: GegoDevice[],
    unos: UnosShipment[]
  ): ShipmentStore[] {
    const matches: ShipmentStore[] = [];
    for (let unosShipment of unos) {
      try {
        if (typeof unosShipment.VendorShipmentId === "undefined") {
          continue;
        }

        const gegoShipment = gego.find(
          (gship) =>
            gship.ShipmentId.toString() === unosShipment.VendorShipmentId
        );
        if (typeof gegoShipment === "undefined") {
          continue;
        }

        const dev = devices.find(
          (dev) => dev.DeviceId === gegoShipment.DeviceId
        );
        if (typeof dev === "undefined") {
          continue;
        }

        const base = {
          id: unosShipment.Id,
          organ: this.fromOrganId(unosShipment.OrganTypeId),
          status: this.fromStatusId(unosShipment.DeviceShipmentStatusId),
          destination: `${unosShipment.IntendedDestinationCode}-${unosShipment.IntendedDestinationType}`,
          deviceName: dev.DeviceName,
          donorId: unosShipment.DonorId,
        };

        const origin = {
          lat: gegoShipment.OriginLatitude,
          lng: gegoShipment.OriginLongitude,
        };

        let destination: LatLng | null = null;
        if (gegoShipment.DestinationLatitude !== 0) {
          destination = {
            lat: gegoShipment.DestinationLatitude,
            lng: gegoShipment.DestinationLongitude,
          };
        }

        const locations = gegoShipment.Locations;
        locations.sort(
          (a, b) =>
            new Date(b.ReportTime).valueOf() - new Date(a.ReportTime).valueOf()
        );
        const lastAt = {
          lat: locations[0].Latitude,
          lng: locations[0].Longitude,
        };

        matches.push({
          ...base,
          gegoLink: unosShipment.ViewShareUrl,
          battery: dev?.Battery ?? null,
          deviceName: dev?.DeviceName ?? "UNKNOWN DEVICE",
          destinationDescription: gegoShipment.DestinationDescription,
          destinationParsed: this.parseDescription(
            gegoShipment.DestinationDescription ?? "To be defined"
          ),
          originDescription: gegoShipment.OriginDescription,
          originParsed: this.parseDescription(gegoShipment.OriginDescription),
          lastUpdated: new Date(locations[0].ReportTime),
          lastAt,
          origin,
          currentLocation:
            dev.LastLocationGeneralDescription.trim() == "Near"
              ? null
              : dev.LastLocationGeneralDescription,
          destinationLatLng: destination,
          locationHistory: locations,
          deliveredDate: unosShipment.DeliveredDateTimeUtc
            ? forceUtcTimeZone(unosShipment.DeliveredDateTimeUtc)
            : undefined,
          canceledDate: unosShipment.CanceledDateTimeUtc
            ? forceUtcTimeZone(unosShipment.CanceledDateTimeUtc)
            : undefined,
        });
      } catch (error) {
        console.log(error);
      }
    }

    return matches;
  }

  parseDescription(desc: string): LocationName {
    const split = desc.split(/ - /, 3);

    if (split.length >= 2) {
      return {
        opo: split[0],
        name: split.length === 3 ? split[1] : undefined,
        address: split.length === 3 ? split[2] : split[1],
      };
    } else {
      return { address: desc };
    }
  }

  fromOrganId(id: number): { id: number; name: string } {
    if (this.organLookup === null) {
      return { id, name: "NOT LOADED" };
    }
    const organ = this.organLookup.find(
      (organ) => organ.Id === id
    )?.Description;
    return {
      id,
      name: organ ?? "UNKNOWN",
    };
  }

  fromStatusId(id: number): { id: number; name: string } {
    if (this.statusLookup === null) {
      return { id, name: "NOT LOADED" };
    }
    const status = statusName(id);
    return {
      id,
      name: status[0].toUpperCase() + status.substring(1),
    };
  }

  getStringOptions(
    store: ShipmentStore[],
    key: "donorId" | "destination" | "deviceName"
  ) {
    return [...new Set(store.map((shipment) => shipment[key]))].map((x) => ({
      name: x,
      id: x,
    }));
  }

  getOptions(): Observable<{
    [Property in ShipmentFilters]: FilterSelectModel[];
  }> {
    return this.shipmentStore.pipe(
      map(({ store, organs }) => {
        const organOptions =
          organs.map(({ Id: id, DisplayOrder: order, Description: name }) => ({
            id,
            name,
            order,
          })) ?? [];
        organOptions.sort((a, b) => a.order - b.order);
        return {
          organ: organOptions,
          status: [],
          donorId: this.getStringOptions(store, "donorId"),
          destination: this.getStringOptions(store, "destination"),
          deviceName: this.getStringOptions(store, "deviceName"),
        };
      })
    );
  }

  getStore() {
    return this.shipmentStore.pipe(map((x) => x.store));
  }

  getFilteredStore() {
    return combineLatest({
      shipments: this.shipmentStore,
      filters: this.filterService.getFilter(),
    }).pipe(
      map(({ shipments, filters }) => ({
        ...shipments,
        filterUpdate:
          shipments.opoUpdate || filters.updated > shipments.updated,
        store: filters.filter(shipments.store),
      })),
      debounceTime(20)
    );
  }

  clearStore() {
    this.shipmentStore.next({
      organs: this.organLookup ?? [],
      statuses: this.statusLookup ?? [],
      store: [],
      loaded: true,
      opoUpdate: false,
      updated: new Date(),
    });
  }
}
