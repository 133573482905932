import { InjectionToken } from "@angular/core";
import { Observable, Subscription } from "rxjs";

export interface AuthService {
  login(code?: string): Observable<boolean>;

  logout(): Subscription;

  isActive(): boolean;

  refresh(): void;
}

export let AuthService = new InjectionToken<AuthService>("AuthService");
